import React, { Component } from 'react';
import { withRouter } from 'react-router';

import { UserPresentColumn } from '../components/UserPresentColumn';

import Auth from '../utils/Auth.js';
import Users from '../utils/Users.js';

class Kados extends Component
{
	static displayName = Kados.name;

	constructor( props ) 
	{
		super( props );
		
		this.state = {
			users: [],
		};
		
		if( !Auth.getToken()  ) {
		    this.props.history.push("/");
		}
	}
	
	
	componentDidMount() {
		this._asyncRequest = Users.getUsers().then(
			() => {
				this.setState({
					users: Users.users || [],
				});
			}
	  );
	}
	
	render()
	{
		var userColumns = [];
		for( var i in this.state.users ) {
			var u = this.state.users[ i ];
			userColumns.push( <UserPresentColumn key={u.id}  id={u.id} name={u.name} avatar={u.avatar}  /> );
		}
		
		return (
				<div>
					<div className="row justify-content-center">
						{userColumns}
					</div>
				</div>
				);
	}
}

export default withRouter( Kados );