import React, { Component } from 'react';
import { Navbar, NavbarBrand } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import $ from 'jquery';
import Auth from '../utils/Auth.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export class NavMenu extends Component {
  static displayName = NavMenu.name;

	constructor( props )
	{
		
		super(props);

		this.state = {
			isLoggedIn: Auth.getToken() 
		};
		
		
		var self = this;
		var onSetTokenPrev = Auth.onSetToken;
		Auth.onSetToken = function()
		{
			if( onSetTokenPrev ) onSetTokenPrev();
			self.setState({isLoggedIn: Auth.token ? true : false });
		};
  }

  
  showMyWishlist()
  {
	  $('#layout').addClass('showing-mine').removeClass('showing-other');
	  $("#btn-show-mine").addClass('active');
	  $("#btn-show-other").removeClass('active');
	  localStorage.setItem('showing', 'showing-mine');
  }
  
  showOtherWishlists()
  {
	  console.log('show others');
	  $('#layout').removeClass('showing-mine').addClass('showing-other');
	  $("#btn-show-mine").removeClass('active');
	  $("#btn-show-other").addClass('active');
	  localStorage.setItem('showing', 'showing-other');
  }
  
  onLogout()
  {
	  Auth.setToken(null);
  }

  render () {
	var className = localStorage.getItem('showing');
	if( !className ) className = 'showing-mine';
	
	var mineActive = ( className === 'showing-mine' ) ? 'active' : '';
	var otherActive = ( className === 'showing-other' ) ? 'active' : '';
		
    return (
      <header>
        <Navbar className="navbar-expand-sm navbar-toggleable-sm bg-info bg-gradient border-bottom box-shadow mb-3 fixed-top" >
          <div >
			<div className="row">
				<div className="col-12">
					{ this.state.isLoggedIn && 
						<button className="float-end btn btn-light btn-sm" onClick={this.onLogout.bind(this)}>
							<FontAwesomeIcon icon="sign-out-alt" /> Uitloggen
						</button>
						}
					<NavbarBrand tag={Link} to="/" className="text-light">Kerst Wensenlijst</NavbarBrand>	
				</div>
			</div>
			{ this.state.isLoggedIn && 
				<div className="row">
					<button id="btn-show-mine" className={'col-6 btn btn-outline-primary btn-light rounded-0 ' + mineActive} onClick={this.showMyWishlist.bind(this)}>
						Mijn Wensenlijst
					</button>
					<button id="btn-show-other" className={'col-6 btn btn-outline-primary btn-light rounded-0 ' + otherActive} onClick={this.showOtherWishlists.bind(this)}>
						Wensenlijst Anderen
					</button>
				</div>
			}
          </div>
        </Navbar>
      </header>
    );
  }
}
