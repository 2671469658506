import Ajax from './Ajax.js';

var auth =
{
	setToken( token )
	{
		if( token === 'INVALID' ) token = null;
		
		auth.token = token;
		if( token )
			localStorage.setItem('authtoken', token);
		else 
			localStorage.removeItem('authtoken');
		
		if( auth.onSetToken ) 
			auth.onSetToken();
		
		setTimeout( function() {
			if( auth.token ) {
				Ajax.post(Ajax.apiUrl('account/renew'), {});
			}
		}, 3600*1000);
	},
	getToken()
	{
		if( !auth.token )
		{
			var token = localStorage.getItem('authtoken');
			if( token ) auth.setToken( token );
		}
		
		return auth.token;
	},
	onSetToken: null
}

export default auth;
