import Ajax from './Ajax.js';

var users =
{
	users: [],
	presents: [],
	async getUsers()
	{
		if( users.users.length === 0 ) {
			console.log('request');
			await users.requestUsers();
		}
		return users.users;
	},
	async requestUsers()
	{
		return new Promise(function(resolve, reject) {
				Ajax.get(Ajax.apiUrl('kerst/users'))
						.done( function( data ) {
							for( var i in data )
							{
								users.users.push( data[ i ] )
							}
						})
						.fail(function()
						{
							console.error('failed to get users');
						})
						.always(function()
						{
							resolve();
						});
		});
	},
	async getPresents( uid )
	{
		await users.requestPresents( uid );
		return users.presents[uid];
	},
	async requestPresents( uid )
	{
		return new Promise(function(resolve, reject) {
				users.presents[ uid ] = [];	
				Ajax.get(Ajax.apiUrl('kerst/presents/'+uid))
						.done( function( data ) {
							for( var i in data )
							{
								users.presents[ uid ].push( data[ i ] )
							}
						})
						.fail(function()
						{
							console.error('failed to get users');
						})
						.always(function()
						{
							resolve();
						});
		});
	}
}

export default users;
