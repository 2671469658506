import React, { Component } from 'react';
import { Form, FormGroup, Input, Label, Button, Alert } from 'reactstrap';
//import { Link } from 'react-router-dom';
import './NavMenu.css';
import $ from 'jquery';
import Ajax from '../utils/Ajax.js';

export class LoginForm extends Component
{

	constructor( props )
	{
		super(props);

		this.state = {
			submitting: false, 
			errorMessage: ""
		};
		
		this.handleSubmit = this.handleSubmit.bind(this);
	}
	
	handleSubmit(event) {
		
		event.preventDefault();
		
		var values = {};
		$.each($('#loginForm').serializeArray(), function(i, field) {
			values[field.name] = field.value;
		});
		
		this.doLoginRequest( values );
		
		this.setState({ submitting: true });
	}
	
	doLoginRequest( data )
	{
		var self = this;
		
		this.setState({ submitting: true, errorMessage: "" });
		Ajax.post( Ajax.apiUrl('account/login'), data )
			.done(function( data ) {
				if( data.error ) {
					self.setState({ errorMessage: data.error });
					self.setState({ submitting: false });
				}
				
				localStorage.setItem('user', JSON.stringify( data ));
				
				// if no error than auth token will be set elsewhere and this form will be destroyed
			})
			.fail(function() {
				console.log( 'fail' );
				self.setState({ errorMessage: "Onbekende Fout. Kon niet inloggen." });
				self.setState({ submitting: false });
			})
			.always(function() {
			});
	}
	
	submitButton()
	{
		const isSubmitting = this.state.submitting;
		
		var submitButton = '';
		if( isSubmitting ) {
			submitButton = <Button disabled>Submit</Button>;
		} else {
			submitButton = <Button>Submit</Button>;
		}
		return submitButton;
	}
	
	errorMessage()
	{
		return !this.state.errorMessage 
				?  ''
				: <Alert color="danger">{this.state.errorMessage}</Alert>
	}

	render()
	{
		var submitButton = this.submitButton();
		var errorMessage = this.errorMessage();
		return (
				<Form id="loginForm" onSubmit={this.handleSubmit}>
					<FormGroup>
						<Label for="exampleEmail">
							Naam
						</Label>
						<Input type="text" id="loginFormUsername" name="username" defaultValue="" autoComplete="false" autoFocus={true} />						
					</FormGroup>
					{' '}
					<FormGroup>
						<Label for="exampleEmail">
							Wachtwoord
						</Label>
						<Input type="password" id="loginFormPassword" name="password"  defaultValue="" autoComplete="false"/>
					</FormGroup>
					{' '}
					{errorMessage}
					{submitButton}
				</Form>
				);
	}
}
