import {Component} from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input, Alert} from 'reactstrap';

import $ from 'jquery';
import Ajax from '../utils/Ajax.js';

export default class PresentForm extends Component
{
	constructor( props )
	{
		super( props );
		
		this.state = {
			errorMessage: ''
		}
	}
	
	componentDidMount()
	{
		window.requestAnimationFrame( 
			() => this.expandTextArea( document.getElementById( 'editPresentFormDescription' ) ) 
		);
	}
	
	componentDidUpdate()
	{
		window.requestAnimationFrame( 
			() => this.expandTextArea( document.getElementById( 'editPresentFormDescription' ) ) 
		);
	}
	
	onTextAreaChange( e )
	{
		this.expandTextArea( e.target );
	}
	
	expandTextArea( el )
	{
		if( el ) {
			el.style.overflow = 'hidden';
			el.style.height = 0;
			el.style.height = el.scrollHeight + 'px';
		}
	}
	
	onSave(event) {
		
		event.preventDefault();
		
		var values = {};
		$.each($('#editPresentForm').serializeArray(), function(i, field) {
			values[field.name] = field.value;
		});
		
		values.present_id = this.props.presentId;
		console.log( 'onSave:', values );
		
		
		var self = this;
		this.savePresent( values )
				.then(function()
				{
					self.props.toggle();
				})
				.catch(function(msg)
				{
					self.setState({errorMessage: msg});
				});
	}
	
	savePresent( data )
	{
		return new Promise(function(resolve,reject){
			Ajax.post( Ajax.apiUrl('kerst/present-save'), data )
				.done(function( data ) {
					console.log( 'done' );
					if( data.error ) {
						reject( data.error );
						return;
					}
					resolve();
				})
				.fail(function() {
					console.log( 'failed' );
					reject( "Onbekende fout" );
				})
				.always(function() {
				});
		});
	}
	
	
	
	errorMessage()
	{
		return !this.state.errorMessage 
				?  ''
				: <Alert color="danger">{this.state.errorMessage}</Alert>
	}
	
	
	renderForm()
	{
		return (
				<Form id="editPresentForm">
					<FormGroup>
						<Label for="editPresentFormTitle">
							Title
						</Label>
						<Input type="text" 
								id="editPresentFormTitle" name="title" 
							   defaultValue={this.props.presentTitle} autoComplete="false" 
							   autoFocus={true} />						
					</FormGroup>
					{' '}
					<FormGroup>
						<Label for="editPresentFormDescription">
							Omschrijving
						</Label>
						<textarea className="form-control overflow-auto" 
								id="editPresentFormDescription" 
								  name="description"  autoComplete="false" 
								  defaultValue={this.props.presentDescription}
								  onChange={this.onTextAreaChange.bind(this)}
								  onKeyUp={this.onTextAreaChange.bind(this)}
								  onFocus={this.onTextAreaChange.bind(this)}
								  onBlur={this.onTextAreaChange.bind(this)}>	
						</textarea>
					</FormGroup>
					{' '}
					<FormGroup>
						<Label for="editPresentFormLink">
							Link
						</Label>
						<Input type="url" 
								id="editPresentFormLink" 
								name="link" 
								defaultValue={this.props.presentLink} 
								autoComplete="false" />						
						</FormGroup>
					{this.errorMessage()}
				</Form>
		);
	}

	render()
	{
		return (
				<Modal 
					isOpen={this.props.isOpen}
					toggle={this.props.toggle}
					>
					<ModalHeader toggle={this.props.toggle}>
						Kado
					</ModalHeader>
					<ModalBody>
						{this.renderForm()}
					</ModalBody>
					<ModalFooter>
						<Button
							color="primary"
							onClick={this.onSave.bind(this)}
							>
							Opslaan
						</Button>
						{' '}
						<Button onClick={this.props.toggle}>
							Annuleer
						</Button>
					</ModalFooter>
				</Modal>
				);
		
	}
}