import React, { Suspense, Component } from 'react';
import { LoginForm } from '../components/LoginForm';
import { withRouter } from 'react-router';
import Auth from '../utils/Auth.js';

class Home extends Component
{
	static displayName = Home.name;
	
	constructor( props ) 
	{
		super( props );
		
		if( Auth.getToken()  ) {
		    this.props.history.push("/kados");
		}
	}

	render()
	{
		return (
				<div>
					<div className="row justify-content-center">
						<div className="col-12 col-sm-8 col-md-6">
							<h2>Inloggen!</h2>
							
							<Suspense fallback={ <div>Loading...</div> } >
								<LoginForm />
							</Suspense>
						</div>
					</div>
				</div>
				);
	}
}

export default withRouter( Home );
