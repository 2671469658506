import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default class UserPresent extends Component
{

	render()
	{
		var idx = 0;
		const URL_REGEX = /(https?:\/\/([^/]+?))(\/.*)?(\s|$)/i;
		

		const renderText = function(text) {
			text = text.replace( /\n/g, "\n ");
			return text
				  .split(" ")
				  .map(function(part) {
					  if( URL_REGEX.test(part)) {
						  idx++;
						  var m = URL_REGEX.exec( part );
						  return ( <span key={idx}>
									  <a href={part} target="_blank" rel="noreferrer" >
										  {m[2]}
									  </a> 
									  {' '}
									  <a href={part} target="_blank" rel="noreferrer" >
										  <FontAwesomeIcon icon="external-link-alt" />
									  </a>{' '}
							  </span>);
						  } 

						  return part + " ";
					  });
		}
		
		var description = renderText( this.props.description );
		var link = renderText( this.props.link );
	
		return (
				<div className="card m-2">
					<div className="card-header">
						{ this.props.link &&
							<span className="float-end">{link}</span>
						}
						<b className="card-title">{this.props.title}</b>
					</div>
					<div className="card-body" style={{whiteSpace: 'pre-line'}}>
						{description}
					</div>
					{ this.props.canEdit && 
						<div className="card-footer">
							<button className="btn btn-sm btn-primary" onClick={this.props.onEditPresent}>
								<FontAwesomeIcon icon="edit" /> Bewerken
							</button>
							<button className="btn btn-sm btn-danger float-end" onClick={this.props.onDeletePresent}>
								<FontAwesomeIcon icon="trash-alt" /> Verwijderen
							</button>
						</div>
					}
				</div>
				);
	}
}
