import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu.js';
import './Layout.css';

export class Layout extends Component
{
	static displayName = Layout.name;

	render()
	{
		var className = localStorage.getItem('showing');
		if( !className ) className = 'showing-mine';
		
		return (
				<div id="layout" className={className}>
					<NavMenu/>
					<Container fluid>
						{this.props.children}
					</Container>
				</div>
				);
	}
}
