//import logo from './logo.svg';
import './App.css';
import React, { Component } from 'react';
import { Route, withRouter } from 'react-router';
import { Layout } from './components/Layout';
import Auth from './utils/Auth.js';

import Home from './routes/Home';
import Kados from './routes/Kados';


import { library } from '@fortawesome/fontawesome-svg-core'
import { fas} from '@fortawesome/free-solid-svg-icons'

library.add(fas);

class App extends Component
{
	constructor( props )
	{
		
		super(props);

		this.state = {
			isLoggedIn: Auth.getToken() 
		};
		
		var self = this;
		var onSetTokenPrev = Auth.onSetToken;
		Auth.onSetToken = function()
		{
			if( onSetTokenPrev ) onSetTokenPrev()
			self.setState({isLoggedIn: Auth.token ? true : false });
			if( Auth.token ) {
				self.props.history.push("/kados");
			}
			else {
				self.props.history.push("/");
			}
		};
		
		//this.handleSubmit = this.handleSubmit.bind(this);
	}
	
	render()
	{
		return (
				<Layout>
					<div className="north_pole_sign">
					</div>
					<Route exact path='/' component={Home} />
					<Route exact path='/kados' component={Kados} />
				</Layout>
				);
	}
}

export default withRouter( App );