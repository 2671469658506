import React, { Component } from 'react';
import Users from '../utils/Users.js';
import UserPresent from './UserPresent.js';
import PresentForm from './PresentForm.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Ajax from '../utils/Ajax.js';
import './UserPresentColumn.css';

export class UserPresentColumn extends Component
{

	constructor( props )
	{
		super(props);
		
		var userData = JSON.parse( localStorage.getItem('user'));
		this.state = {
			canEdit: userData.id === this.props.id,
			isModalOpen: false,
			editingPresent: {},
			presents: []
		};
		
	}
	
	
	componentDidMount() {
		this.requestPresents();
	}
	
	requestPresents()
	{
		this._asyncRequest = Users.getPresents(this.props.id).then(
			() => {
				this.setState({
					presents: Users.presents[ this.props.id ] || [],
				});
			}
	  );
	}
	
	
	modalToggle()
	{
		var isOpen = this.state.isModalOpen;
		this.setState({isModalOpen: !isOpen});
		
		this.requestPresents();
	}
	
	onEditPresent( present )
	{
		if( !this.state.canEdit ) return;
		
		this.setState({isModalOpen: true, editingPresent: present || {} });
	}
	
	onDeletePresent( present )
	{
		if( !this.state.canEdit ) return;
		
		if( window.confirm("Weet je zeker dat je het wil verwijderen?" ) ) {
			var self = this;
			this.deletePresent( present.id )
					.then(function()
					{
						self.requestPresents();
					})
					.catch(function(msg)
					{
						alert( msg );
					});
		}
	}
	
	deletePresent( id )
	{
		return new Promise(function(resolve,reject){
			Ajax.post( Ajax.apiUrl('kerst/present-delete'), {present_id:id} )
				.done(function( data ) {
					if( data.error ) {
						reject( data.error );
						return;
					}
					resolve();
				})
				.fail(function() {
					reject( "Onbekende fout" );
				})
				.always(function() {
				});
		});
	}
	
	renderPresents()
	{
		var self = this;
		var presents = [];
		for( var i in this.state.presents ) {
			var p = this.state.presents[ i ];
			var cbEdit = (function(p){ return function() { self.onEditPresent(p); } })(p);
			var cbDelete = (function(p){ return function() { self.onDeletePresent(p); } })(p);
			presents.push( <UserPresent key={p.id}  
										id={p.id} 
										title={p.title} 
										description={p.description}
										link={p.link}
										onEditPresent={cbEdit} 
										onDeletePresent={cbDelete}
										canEdit={this.state.canEdit} /> );
		}
		
		if( presents.length === 0 ) {
			presents.push( <div className="text-center alert">...</div> );
		}
		return presents;
	}
	
	renderPresentForm()
	{
		return (<PresentForm	
					isOpen={this.state.isModalOpen}
					toggle={this.modalToggle.bind(this)}
					presentId={this.state.editingPresent.id||''}
					presentTitle={this.state.editingPresent.title||''}
					presentDescription={this.state.editingPresent.description||''}
					presentLink={this.state.editingPresent.link||''}
					/>);
	}
	
	render()
	{
		var isMine = this.state.canEdit;
		var className = isMine?'wishlist-mine':'wishlist-other';
		return (
				<div className={'col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3 p-2 ' + className }>
					<div className=" user-panel border rounded h-100">
						{
							!isMine && 
							<h3 className="text-center">
								{this.props.name}
							</h3>
						}
						{
							!isMine && 
							<div className="text-center">
								<img src={this.props.avatar}  className="avatar border rounded" alt=""/>
							</div>
						}
						<div>
							{this.renderPresents()}
						</div>
						<div>
							{this.renderPresentForm()}
						</div>
						<div className="clearfix text-center">
							<br/>
							{
								isMine && 
								<button className="btn btn-sm btn-primary " 
										onClick={this.onEditPresent.bind(this)}>
										<FontAwesomeIcon icon="plus-square" /> Toevoegen
								</button>

							}
						</div>
					</div>
					<br/>
				</div>
				);
	}
}
